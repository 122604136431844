
export default {
    seo: {
        title: 'Правила и документы Платежной системы dengi.ru',
        description: 'Правила и документы Платежной системы dengi.ru'
    },
    // Необходимо для рендеринга контента
    variantContent: {
        registration: 'registration',
        rules: 'rules',
        creditOrgs: 'credit-orgs',
    },

    listMenu: {
        aboutPS: {
            title: 'О ПЛАТЕЖНОЙ СИСТЕМЕ',
            subMenu: [
                {
                    title: 'Документы о регистрации',
                    query: 'registration'
                },
                {
                    title: 'Правила Платежной системы',
                    query: 'rules'
                },
            ]
        },
        сonnects: {
            title: 'ПОДКЛЮЧЕНИЕ УЧАСТНИКА ПС',
            subMenu: [
                {
                    title: 'Кредитным организациям',
                    query: 'credit-orgs'
                },
            ]
        },
    },

    registration: {
        title: 'Документы о регистрации'
    },

    rules: {
        title: 'Правила Платежной системы'
    },

    creditOrgs: {
        title: 'Кредитным организациям'
    },
    
}
