
export default {
    seo: {
        title: 'Правила и документы Платежной системы dengi.ru',
        description: 'Правила и документы Платежной системы dengi.ru'
    },
    // Необходимо для рендеринга контента
    variantContent: {
        registration: 'registration',
    },

    listMenu: {
        aboutBank: {
            title: 'О ПЛАТЕЖНОЙ СИСТЕМЕ',
            subMenu: [
                {
                    title: 'Документы о регистрации',
                    query: 'registration'
                }
            ]
        },
    },

    registration: {
        title: 'Документы о регистрации'
    },
    
}
