import React from 'react';
import Layout from "../layout/Layout";
import SEO from "../components/seo";

import Title from "../components/typography/Title";
import InfoBlock from "../blocks/InfoBlock";
import PageMenu from "../components/PageMenu";
import PageMenuButton from "../components/PageMenuButton";
import Rectangle from "../components/Rectangle";

import '../styles/pages/legalInformation.scss'
import '../styles/components/titleMenu.scss'
import '../styles/components/line.scss'

const TemplateDocsPSPage = ({ children, locale, section, messages, messagesMain }) => {

    const [open, setOpen] = React.useState(false);

    const closeFn = React.useCallback(() => {
        if (open) {
            document.body.classList.remove('showPageMobileMenu');
            setOpen(false);
        }
    }, [open]);

    const openFn = React.useCallback(() => {
        if (!open) {
            document.body.classList.add('showPageMobileMenu');
            setOpen(true);
        }
    }, [open]);

    const toggle = React.useCallback(() => {
        if (open) {
            closeFn();
        } else {
            openFn();
        }
    }, [open, openFn, closeFn]);

    return (
        <Layout locale={locale}>
            <SEO
                title={messages.seo.title}
                description={messages.seo.description}
            />
            <InfoBlock
                configBlock={{
                    baseBorder: true,
                }}
                config={{
                    className: 'Flex-row'
                }}
            >
                <div className="LegalInformation-Menu Flex-row">
                    <div className="LegalInformation-Menu-Inner LegalInformation-Menu-Inner-Custom" >
                        {Object.entries(messagesMain.listMenu).map((value,index) => {
                            return <PageMenu
                                key={`${section}_${index}`}
                                toChoose={section}
                                page='rules_documents_dengi_ru'
                                title={value[1].title}
                                listLinks={value[1].subMenu}
                                closeFn={closeFn}
                            />
                        })}
                    </div>
                    <div className="DividingLine DividingLine-right"/>
                </div>

                <div className="LegalInformation">
                    <div className="LegalInformation-container">
                        <div className="Title-Menu">
                            <Title
                                headerStyleNumb={'7'}
                                color="none"
                                className="Block-Title"
                                innerHTML={messages.title}
                            />

                            <div className="Header-Cell Header-Cell_mobileMenuButton_md">
                                <PageMenuButton onClick={toggle} open={open} />
                            </div>
                        </div>
                        <div className="PageMenu-open">
                            <div className="PageMenu-open-Title">
                                <Title
                                    headerStyleNumb={"7"}
                                    color="none"
                                    className="Block-Title"
                                    innerHTML={messages.title}
                                />
                                <div className="Header-Cell Header-Cell_mobileMenuButton">
                                    <PageMenuButton onClick={toggle} open={open} />
                                </div>
                            </div>
                            <div className="LegalInformation-Menu-Inner">
                                {Object.entries(messagesMain.listMenu).map((value, index) => {
                                    return <PageMenu
                                        key={`${section}_${index}`}
                                        toChoose={section}
                                        page='rules_documents_dengi_ru'
                                        title={value[1].title}
                                        listLinks={value[1].subMenu}
                                        closeFn={closeFn}
                                    />
                                })}
                            </div>
                        </div>

                        <div>
                            {children}
                        </div>
                    </div>
                </div>
            </InfoBlock>
        </Layout>
    )
};

export default TemplateDocsPSPage;
