import React from "react"

import { Link } from "gatsby"

import '../styles/components/pageMenu.scss';

import useLanguage from "../hooks/useLanguage";

import PropTypes from "prop-types";



const PageMenu = ({ listLinks = [], title = '', toChoose = '', page='', closeFn={} }) => {
    const [currentLanguage] = useLanguage();

    const prefixUrl = React.useMemo(() => {
        return (currentLanguage || 'ru') === 'en' ? '/en' : ''
    }, [currentLanguage]);

    return (
        <div className="PageMenu">
            <div className="PageMenu-Inner">
                <div className="container">
                    <div className="PageMenu-Cell">
                        <div className="PageMenu-CellTitle">{title}</div>
                        <div className="listPageMenuLinks">
                            {listLinks.map((item) => (
                                <Link
                                    key={`PageMenu_${item.query}`}
                                    to={`${prefixUrl}/${page}/${item.query}`}
                                    className={`pageMenuLink ${ toChoose === item.query ? 'active' : '' }`}
                                    activeClassName="active"
                                    onClick={closeFn}
                                >
                                    {item.title}
                                </Link>)
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export const PageMenuPropTypes = {
    toChoose: PropTypes.any,
    listLinks: PropTypes.array,
    title: PropTypes.string,
    page: PropTypes.string
};

PageMenu.propTypes = PageMenuPropTypes;

export default PageMenu;
